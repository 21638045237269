import { SubmittedTalentCard } from 'components/custom/talent/submitted-talent-card';
import React from 'react';

import { Box, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Job, JobMatch, SuggestingStageEnum } from '@libs/graphql-types';
import Typography from '@libs/ui/components/typography';

import ArrowMatchesTabs from './ArrowMatchesTabs';

interface JobCandidatesPipelineProps {
  job: Job;
}

const useStyles = makeStyles(() => ({
  tabs: {
    '& > button': {
      height: 32,
    },
  },
  talents: {
    background: 'rgba(238, 238, 238, 1)',
    maxHeight: 300,
    overflowY: 'auto',
  },
}));

export const mapMatches = (job: Job) => {
  const count: Record<SuggestingStageEnum | string, number> = {};
  const mapped = job?.matches?.reduce((acc, match) => {
    if (!match || !match.suggesting_stage) {
      return acc;
    }
    count[match.suggesting_stage] = (count[match.suggesting_stage] || 0) + 1;
    return {
      ...acc,
      [match.suggesting_stage]: (acc[match.suggesting_stage] || []).concat(
        match,
      ),
    };
  }, {} as Record<SuggestingStageEnum, JobMatch[]>);

  return {
    mapped: (mapped || {}) as Record<SuggestingStageEnum, JobMatch[]>,
    count,
  };
};

// const mockMatch: Partial<JobMatch> = {
//   id: '1',
//   submitted_by_talent_matcher: {
//     id: '123',
//     first_name: 'Talent',
//     last_name: 'Matcher',
//     first_name_abac: {
//       value: 'Talent',
//     },
//     last_name_abac: {
//       value: 'Matcher',
//     },
//   },
//   talent_invitation: {
//     id: '1',
//     first_name: 'John',
//     last_name: 'Doe',
//     created_at: '2021-01-01T00:00:00Z',
//     uid: '1123',
//     is_accepted: false,
//   },
// } as JobMatch;

export const JobCandidatesPipeline = ({ job }: JobCandidatesPipelineProps) => {
  const classes = useStyles();
  const [currentStage, setCurrentStage] = React.useState<
    SuggestingStageEnum | undefined
  >();
  const { count, mapped } = mapMatches(job);

  const matches = currentStage ? mapped[currentStage] || [] : [];

  if (!job?.matches?.length) {
    return null;
  }

  const currentMatches = matches.sort((a, b) =>
    new Date(b.created_at).getTime() < new Date(a.created_at).getTime()
      ? -1
      : 1,
  );

  return (
    <Box mt={4}>
      <Box mb={2}>
        <Typography variant="caption">
          Total candidates in pipeline: {matches?.length || 0}
        </Typography>
      </Box>
      <ArrowMatchesTabs
        value={currentStage}
        count={count || {}}
        className={classes.tabs}
        onChange={(stage) =>
          setCurrentStage(stage === currentStage ? undefined : stage)
        }
      />

      <Collapse in={!!currentStage}>
        <Box className={classes.talents} py={1}>
          {currentMatches.map((match) => (
            <Box mx={4} my={4}>
              <SubmittedTalentCard match={match} />
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};
